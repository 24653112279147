import React, { useEffect } from "react";
import { Route, Switch } from "react-router";
import { BrowserRouter as Router } from "react-router-dom";
// import Error from "./Error";
import "../src/css/style.css";
import "../src/css/header.css";
import "../src/css/footer.css";
import "../src/css/responsive.css";
import "../src/font/stylesheet.css";
import ScrollToTop from "./ScrollToTop";
import Home from "./Home";
import { Svg } from "./components/Svg";
import { Retail } from "./Retail";
import { Smarthome } from "./Smarthome";
import { Smartcity } from "./Smartcity";
import { Enterprise } from "./Enterprise";
import { Ipbox } from "./Ipbox";
import { Iptv } from "./Iptv";
import { Androidtv } from "./Androidtv";
import { Netplushd } from "./Netplushd";
import { Book } from "./Book";
import { Securitycamera } from "./Securitycamera";
import { Aboutus } from "./Aboutus";
import { Contactus } from "./Contactus";
import { Login } from "./Login";
import { Entertainmentplan } from "./Entertainmentplan";
import { Broadbandplan } from "./Broadbandplan";
// import { Antivirus } from "./Antivirus";
import { Getconnection } from "./Getconnection";
import { Download } from "./Download";
import { Media } from "./Media";
import { News } from "./News";
import { Magicbox } from "./Magicbox";
import { Hungama } from "./Hungama";
import { Disclaimer } from "./Disclaimer";
import { Jujhar } from "./Jujhar";
import { Privacy } from "./Privacy";
import { Terms } from "./Terms";
import { Faqs } from "./Faqs";
import { Eventdetails } from "./Eventdetails";
import { Paybill } from "./Paybill";
import { Advancerent } from "./Advancerent";
import { Ott } from "./Ott";
import { Leadline } from "./Leadline";
import { Wifi } from "./Wifi";
import { Dualband } from "./Dualband";
import { Voice } from "./Voice";
import { Refundpolicy } from "./Refundpolicy";
import { CoveDrive } from "./CoveDrive";
import { ErrorPage } from "./ErrorPage";

const App = () => {
  useEffect(() => {
    // const script = document.createElement("script");
    // script.async = true;
    // script.type = "text/javascript";
    // script.src = "https://app.artibot.ai/loader.js";
    // document.head.appendChild(script);

    // script.onreadystatechange = script.onload = () => {
    //   if (
    //     !window.a ||
    //     (script.readyState &&
    //       script.readyState !== "complete" &&
    //       script.readyState !== "loaded")
    //   ) {
    //     new window.ArtiBot({ i: "c7a9496e-b6b2-4b42-9501-5413c03ac3b1" });
    //     window.a = true;
    //   }
    // }; 
  }, []);
  return (
    <>
      <Router>
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={Home}></Route>
          <Route exact path="/retail" component={Retail}></Route>
          <Route exact path="/voice" component={Voice}></Route>
          <Route
            exact
            path="/smart-home-automation"
            component={Smarthome}
          ></Route>
          <Route
            exact
            path="/smart-city-projects"
            component={Smartcity}
          ></Route>
          <Route exact path="/enterprise" component={Enterprise}></Route>
          <Route exact path="/ip-box" component={Ipbox}></Route>
          <Route exact path="/ip-tv" component={Iptv}></Route>
          <Route exact path="/android-tv" component={Androidtv}></Route>
          <Route exact path="/netplus-hd-bundle" component={Netplushd}></Route>
          <Route exact path="/book-now/:slug" component={Book}></Route>
          <Route
            exact
            path="/system-intigration-projects"
            component={Securitycamera}
          ></Route>
          <Route
            exact
            path="/entertainment-plans"
            component={Entertainmentplan}
          ></Route>
          <Route
            exact
            path="/entertainment-plans/:slug"
            component={Entertainmentplan}
          ></Route>
          <Route
            exact
            path="/broadband-plans"
            component={Broadbandplan}
          ></Route>
          <Route
            exact
            path="/broadband-plans/:slug"
            component={Broadbandplan}
          ></Route>
          <Route exact path="/ott-plans" component={Ott}></Route>
          <Route exact path="/ott-plans/:slug" component={Ott}></Route>
          <Route exact path="/about-netplus" component={Aboutus}></Route>
          <Route exact path="/contact-us" component={Contactus}></Route>
          {/* <Route exact path="/antivirus" component={Antivirus}></Route> */}
          {/* <Route
            exact
            path="/get-new-connection"
            component={Getconnection}
          ></Route> */}
          <Route exact path="/download" component={Download}></Route>
          <Route exact path="/media" component={Media}></Route>
          <Route exact path="/news" component={News}></Route>
          <Route
            exact
            path="/pm-wani-wi-fi-launch-event-chandigarh"
            component={Eventdetails}
          ></Route>
          <Route exact path="/login" component={Login}></Route>
          <Route exact path="/magic-box" component={Magicbox}></Route>
          <Route exact path="/hungama" component={Hungama}></Route>
          <Route exact path="/disclaimer" component={Disclaimer}></Route>
          <Route exact path="/privacy-policy" component={Privacy}></Route>
          <Route exact path="/terms-conditions" component={Terms}></Route>
          <Route exact path="/refund-policy" component={Refundpolicy}></Route>
          <Route exact path="/faqs" component={Faqs}></Route>
          <Route exact path="/jujhar-group" component={Jujhar}></Route>
          <Route exact path="/pay-bill" component={Paybill}></Route>
          <Route
            exact
            path="/advance-rental-scheme"
            component={Advancerent}
          ></Route>
          <Route exact path="/leadline-phone" component={Leadline}></Route>
          <Route exact path="/wifi-extender" component={Wifi}></Route>
          <Route exact path="/dualband-router" component={Dualband}></Route>
          <Route exact path="/svg" component={Svg}></Route>
          <Route exact path="/cloud-storage" component={CoveDrive}></Route>
          <Route component={ErrorPage}></Route>
        </Switch>
      </Router>
    </>
  );
};

export default App;
