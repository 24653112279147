import React from 'react'
import { Header } from './components/Header'
import { Footer } from './components/Footer'
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import { Medianav } from './Medianav';
import { Years } from './Years';
import { Link } from 'react-router-dom';
import { Contactform } from './components/Contactform';

export const Download = () => {
    return (
        <>
            <Header />
            <section id='banner' className='p-0'>
                <div className='banner booknow filter m-0 h-120'>
                </div>
            </section>            
            <section id='media'>
            <div className='media'>
                <div className='container'>
                    <div className='row'>
                        <div className='web-container'>
                            <div className='mediarow'>
                               
                                <div className='butn'>
                                                    <div className='buttnprop'>
                                                        <a href='https://netplus.co.in/Fastway-1.0.apk' target='_blank'><span>IPTV APP APK</span>
                                                        </a>
                                                    </div>
                                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </section>
            {/* <Contactform /> */}
            <Footer />
        </>
    )
}
