import React, { useState, useEffect, useRef } from "react";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { Contactform } from "./components/Contactform";
import axios from "axios";


export const Book = ({ match }) => {
  const [categories, setCategories] = useState([]);
  const [plans, setPlans] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedPlan, setSelectedPlan] = useState("");
  const [allDataFetched, setAllDataFetched] = useState(false);
  const [planselector, setplanselector] = useState([]);
  

  useEffect(() => {
    axios
      .get(window.weburl + `api/get_decrypt.php?pid=${planID}`)
      .then((response) => setplanselector(response.data.cat_id))
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responsedt = await axios.get(
          window.weburl + `api/get_decrypt.php?pid=${planID}`
        );
        const curCatName = responsedt.data.cat_id;
        const curPlanName = responsedt.data.plan_id;
        const response = await axios.get(
          window.weburl + "api/get_categories.php"
        );
        setCategories(response.data);
        setSelectedCategory(curCatName);
        getPlans(curCatName);
        setSelectedPlan(curPlanName);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // useEffect(() => {
  //   axios.get(window.weburl + 'api/get_categories.php')
  //     .then(response => setCategories(response.data))
  //     .catch(error => console.log(error));
  //     setAllDataFetched(true);
  // }, []);

  function getPlans(category) {
    axios
      .get(window.weburl + `api/get_plans.php?cid=${category}`)
      .then((response) => setPlans(response.data))
      .catch((error) => console.log(error));
  }

  var decodeHTML = function (html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };

  function createMarkup(content) {
    return { __html: decodeHTML(content) };
  }

  const planID = match.params.slug;

  const [planHeader, setplanHeader] = useState([]);
  useEffect(() => {
    axios
      .get(window.weburl + `api/get_current_plan.php?pid=${planID}`)
      .then((response) =>
        setplanHeader(
          (response.data[0].catID ? response.data[0].category + " " : "") +
          (response.data[0].pname ? response.data[0].pname + " " : "") +
          (response.data[0].price
            ? "<i class='finr'></i>" + response.data[0].price
            : "") +
          (response.data[0].ptitle ? " With " + response.data[0].ptitle : "")
        )
      )
      .catch((error) => console.log(error));
  }, []);

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  useEffect(() => {
    axios
      .get(window.weburl + "api/get_states.php")
      .then((response) => setStates(response.data))
      .catch((error) => console.log(error));
  }, []);

  function getCities(state) {
    axios
      .get(window.weburl + `api/get_cities.php?sid=${state}`)
      .then((response) => setCities(response.data))
      .catch((error) => console.log(error));
  }

  // const [planselector, setplanselector] = useState([]);
  // useEffect(() => {
  //   axios.get(window.weburl + `api/get_decrypt.php?pid=${planID}`)
  //     .then(response => document.getElementById('selectedCategory').value=response.data.cat_id)
  //     .catch(error => console.log(error));
  // }, []);

  // Insert Query Start
  const [user_name, setusername] = useState();
  const [user_mobile, setusermobile] = useState();
  const [user_address, setuseraddress] = useState();
  const [user_email, setuseremail] = useState();

  // Focus
  const ref_category = useRef(null);
  const ref_plan = useRef(null);
  const ref_name = useRef(null);
  const ref_mobile = useRef(null);
  const ref_state = useRef(null);
  const ref_city = useRef(null);
  const ref_address = useRef(null);
  const ref_email = useRef(null);

  const [isDisabled, setIsDisabled] = useState(true);

  const handledisable = () => {
    if (user_name && isValidPhone(user_mobile) && user_mobile) {
      const element = document.getElementById("submit_button_text");
      element.classList.remove("disabled");
      setIsDisabled(false);
    } else {
      const element = document.getElementById("submit_button_text");
      element.classList.add("disabled");
      setIsDisabled(true);
    }
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  function isValidPhone(phone) {
    const regex = /^[6-9]\d{9}$/gi;
    return regex.test(phone);
  }


  const handleSubmit = () => {
    if (!selectedCategory) {
      alert("Please select Category");
      ref_category.current.focus();
      return false;
    } else if (!selectedPlan) {
      alert("Please select plan");
      ref_plan.current.focus();
      return false;
    } else if (!user_name) {
      alert("Please enter name");
      ref_name.current.focus();
      return false;
    } else if (!isValidPhone(user_mobile)) {
      alert("Please enter Mobile number");
      ref_mobile.current.focus();
      return false;
    } else {
      document.getElementById("submit_button_text").innerText = "SUBMITTING...";
      const url = window.weburl + "/api/insert_hook.php";
      let fData = new FormData();
      fData.append("catID", selectedCategory ? selectedCategory : "");
      fData.append("planID", selectedPlan ? selectedPlan : "");
      fData.append("user_name", user_name);
      fData.append("user_mobile", user_mobile);
      fData.append("user_state", selectedState ? selectedState : "");
      fData.append("user_city", selectedCity ? selectedCity : "");
      fData.append("user_address", user_address ? user_address : "");
      fData.append("user_email", user_email ? user_email : "");
      fData.append("td_name", "bookings");
      fData.append("utm_url", window.location.href);
      axios
        .post(url, fData)
        .then((response) => {
          if (selectedCategory == "15") {
            alert(
              'Thank you for your booking enquiry. It has been successfully submitted. After clicking "OK", you will be redirected to our portal to set up your account. Your subscription will be ready in seconds. An expert will be in touch with you shortly.'
            );
            const userData = window.btoa(
              JSON.stringify({
                name: user_name,
                mobile: user_mobile,
                email: user_email,
                city: selectedCity,
                state: selectedState,
                planId: selectedPlan,
              })
            );
            window.location.href = `https://subscribe.coveidentity.com/netplus/?plan_id=${selectedPlan}&data=${userData}`;
          } else {
            alert(response.data);
          }
        })
        .catch((error) => alert(error));
      console.log(true);
      document.getElementById("submit_button_text").innerText = "SUBMIT";
      document.getElementById("selectedCategory").value = "";
      document.getElementById("selectedPlan").value = "";
      document.getElementById("user_name").value = "";
      document.getElementById("user_mobile").value = "";
      document.getElementById("selectedState").value = "";
      document.getElementById("selectedCity").value = "";
      document.getElementById("user_address").value = "";
      document.getElementById("user_email").value = "";
      // window.location.reload();
    }
  };



  return (
    <>
      <Header />
      <section id="banner" className="p-0">
        <div className="banner booknow filter m-0 h-120"></div>
      </section>
      <section id="login" className="p-0">
        <div className="booknow filter m-0">
          <div className="container">
            <div className="row">
              <div className="web-container">
                <div className="title m-0">
                  <span>Book Now</span>
                </div>
                <div className="heading">
                  <h4
                    dangerouslySetInnerHTML={createMarkup(
                      "Book Netplus " + planHeader
                    )}
                  ></h4>
                </div>
                <div className="footerform bookingform">
                  <div className="form">
                    <ul>
                      <li>
                        <select
                          name="selectedCategory"
                          id="selectedCategory"
                          value={selectedCategory}
                          onChange={(event) => {
                            setSelectedCategory(event.target.value);
                            getPlans(event.target.value);
                          }}
                          ref={ref_category}
                        >
                          <option value="">Select Category</option>
                          {categories.map((category) => (
                            <option key={category.sno} value={category.sno}>
                              {category.category}
                            </option>
                          ))}
                        </select>
                      </li>
                      <li>
                        <select
                          name="selectedPlan"
                          id="selectedPlan"
                          value={selectedPlan}
                          onChange={(event) =>
                            setSelectedPlan(event.target.value)
                          }
                          ref={ref_plan}
                        >
                          <option value="">Select Plan</option>
                          {plans.map((plan) => (
                            <option
                              key={plan.sno}
                              value={plan.sno}
                              dangerouslySetInnerHTML={createMarkup(
                                plan.pname +
                                " " +
                                plan.ptitle +
                                " " +
                                plan.ptype
                              )}
                            ></option>
                          ))}
                        </select>
                      </li>
                      <li>
                        <input
                          type="text"
                          name="user_name"
                          id="user_name"
                          placeholder="Your name*"
                          pattern="[a-zA-Z\s]+"
                          required
                          value={user_name}
                          onChange={(e) => {
                            setusername(e.target.value);
                          }}
                          onKeyUp={handledisable}
                          ref={ref_name}
                        />
                      </li>
                      <li>
                        <input
                          type="text"
                          name="user_mobile"
                          id="user_mobile"
                          placeholder="Mobile number*"
                          value={user_mobile}
                          onChange={(e) => {
                            setusermobile(e.target.value);
                          }}
                          onKeyUp={handledisable}
                          ref={ref_mobile}
                        />
                      </li>
                      <li>
                        <select
                          name="selectedState"
                          id="selectedState"
                          value={selectedState}
                          onChange={(event) => {
                            setSelectedState(event.target.value);
                            getCities(event.target.value);
                          }}
                          ref={ref_state}
                        >
                          <option value="">Select State</option>
                          {states.map((state) => (
                            <option key={state.stateid} value={state.stateid}>
                              {state.sname}
                            </option>
                          ))}
                        </select>
                      </li>
                      <li>
                        <select
                          name="selectedCity"
                          id="selectedCity"
                          value={selectedCity}
                          onChange={(event) =>
                            setSelectedCity(event.target.value)
                          }
                          ref={ref_city}
                        >
                          <option value="">Select City</option>
                          {cities.map((city) => (
                            <option key={city.cityid} value={city.cityid}>
                              {city.cityname}
                            </option>
                          ))}
                        </select>
                      </li>
                      <li>
                        <textarea
                          placeholder="Enter your address"
                          id="user_address"
                          name="user_address"
                          value={user_address}
                          onChange={(e) => {
                            setuseraddress(e.target.value);
                          }}
                          ref={ref_address}
                        ></textarea>
                      </li>
                      <li>
                        <input
                          type="email"
                          name="user_email"
                          id="user_email"
                          placeholder="Enter your email"
                          value={user_email}
                          onChange={(e) => {
                            setuseremail(e.target.value);
                          }}
                          ref={ref_email}
                        />
                      </li>
                      <li className="buttnprop">
                        <input
                          type="submit"
                          value=""
                          id="submit_button"
                          disabled={isDisabled}
                          onClick={handleSubmit}
                        />
                        <span id="submit_button_text" className="disabled">
                          Submit
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      
<style jsx>{`
        .modal-overlay {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .modal {
          background-color: white;
          padding: 20px;
          border-radius: 5px;
          text-align: center;
          width: 300px;
        }

        .modal button {
          margin-top: 10px;
          padding: 8px 16px;
          background-color: #007bff;
          color: white;
          border: none;
          border-radius: 4px;
          cursor: pointer;
        }

        .modal button:hover {
          background-color: #0056b3;
        }
      `}</style>


      {/* <Contactform /> */}
      <Footer />
    </>
  );
};
